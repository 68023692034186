import styled from "styled-components";

const Video = styled.iframe`
  display: block;
  width: 40vw;
  height: 30vw;
  border-radius: 10px;
  /* max-width: 700px; */
  /* height: 400px; */
  /* height: auto; */

  margin-bottom: 4em;
`;

export default Video;
